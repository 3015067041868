
.back_box {
  width: 1200px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.cont {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.braed {
  margin: 15px 0;
}
.search_header .el-input {
  width: 752px;
  display: inline-block;
}
.search_header .el-input input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tab_box {
  padding: 0 20px;
  /*margin-top: 20px;*/
  margin-bottom: 0px;
  border: 2px solid #dff1f1;
  padding-right: 10px;
  height: 46px;
  line-height: 46px;
  border-radius: 4px;
  color: #808080;
}
.tab_box .el-input {
  width: 217px;
  line-height: 43px;
}
.tab_box .el-input >>> .el-icon-search {
  color: #bd1a2d;
  font-weight: 700;
  cursor: pointer;
}
/*.tab_box.tab_box_last {
    border: none;
    padding-right: 0;
    margin-bottom: 0px;
}*/
.tab_box > span > i {
  color: #bd1a2d;
}
.tab_box .fr a {
  padding: 0 10px;
}
.tab_box .fr a:hover {
  color: #e58e88;
}
.tab_box .fr a.on {
  color: #bd1a2d;
}
.tab_box .fr a:last-child {
  margin-right: 0;
}

.tree_box {
  width: 280px;
  margin-left: 20px;
  padding: 0 12px 10px;
  /*position: relative;*/
  border-top: 2px solid #bd1a2d;
  margin-bottom: 20px;
}
.tree_box.on {
  border: 1px solid #d1d1d1;
  border-top: 2px solid #bd1a2d;
  background: #fff;
  box-shadow: 8px 8px 1px rgba(0, 0, 0, 0.3);
  margin-bottom: 36px;
}
.tree_sort {
  position: relative;
  top: -10px;
  left: 15px;
  width: 200px;
  margin-top: -20px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  /*color: #bd8872;*/
  /*border-bottom: 2px solid #BD1A2D;*/
  cursor: pointer;
  color: #fff;
  background: #bd1a2d;
}
.tree_sort > i {
  float: left;
  width: 18px;
  height: 18px;
  margin-top: 14px;
  margin-left: 10px;
  margin-right: 10px;
  background: url('../assets/img/icon_torr_close.png') no-repeat;
  background-size: 100% 100%;
}
.tree_sort > i.on {
  background-image: url('../assets/img/icon_torr_down.png');
}
.sort_box {
  position: relative;
  /*width: 245px;*/
  width: 100%;
  height: 241px;
  /*325px;*/
  overflow-y: auto;
  /*border-top: 1px solid #ccc;*/
}
.sort_box > p {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-indent: 22px;
  border-bottom: 1px dashed #f0f0f0;
  /*background: #f5f5f5;*/
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sort_box > p:last-child {
  border: none;
}
.sort_box > p:before {
  content: '';
  float: left;
  margin-top: 17px;
  margin-left: 25px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #b5b5b5;
  border-radius: 50%;
}
.sort_box > p.all:before {
  /*display: none;*/
  background: #bd1a2d;
}

.sort_box > p.all {
  font-size: 16px;
  /*text-indent: 0;*/
  text-indent: 22px;
  /*color: #fff;*/
  /*border-top: 1px solid #fff;*/
  color: #bd1a2d;
  /*#55b9c1;*/
  /*background: #b89781;*/
}
.sort_box > p i {
  display: none;
}
.sort_box > p.all i {
  display: block;
  float: left;
  width: 14px;
  height: 14px;
  /*margin-top: 8px;*/
  margin-left: 10px;
  /*background: url("../assets/img/icon_left.png") no-repeat;*/
  background-size: 100% 100%;
}
td a {
  color: #292929;
}
.book-list {
  margin-top: 7px;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 159px;
      margin-right: 13px;
      margin-bottom: 42px;
      position: relative;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: 224px;
        cursor: pointer;
      }
      p {
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        font-size: 14px;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 6px;
        cursor: pointer;
      }
    }
    li:nth-child(5n + 5) {
      margin-right: 0;
    }
  }
}

.pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .total-page {
    font-size: 14px;
    color: #333 !important;
    margin-right: 4px;
  }
}
